import { default as _91publicCodeId_931euEzHPe5sMeta } from "/opt/render/project/src/pages/authorize/[publicCodeId].vue?macro=true";
import { default as errordXxA7XKhjkMeta } from "/opt/render/project/src/pages/authorize/error.vue?macro=true";
import { default as successXBy77aqb6gMeta } from "/opt/render/project/src/pages/authorize/success.vue?macro=true";
import { default as creditsTlTM8JTpvNMeta } from "/opt/render/project/src/pages/credits.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as loginjhbtmcfyq0Meta } from "/opt/render/project/src/pages/login.vue?macro=true";
import { default as notifications6PJ0oUBO6mMeta } from "/opt/render/project/src/pages/notifications.vue?macro=true";
import { default as _91id_93QnRuiuYs8iMeta } from "/opt/render/project/src/pages/probes/[id].vue?macro=true";
import { default as probesl5IWigUfk2Meta } from "/opt/render/project/src/pages/probes.vue?macro=true";
import { default as settings0miuIC4XHwMeta } from "/opt/render/project/src/pages/settings.vue?macro=true";
import { default as tokensuX8uhPnk3oMeta } from "/opt/render/project/src/pages/tokens.vue?macro=true";
export default [
  {
    name: "authorize-publicCodeId",
    path: "/authorize/:publicCodeId()",
    meta: _91publicCodeId_931euEzHPe5sMeta || {},
    component: () => import("/opt/render/project/src/pages/authorize/[publicCodeId].vue")
  },
  {
    name: "authorize-error",
    path: "/authorize/error",
    meta: errordXxA7XKhjkMeta || {},
    component: () => import("/opt/render/project/src/pages/authorize/error.vue")
  },
  {
    name: "authorize-success",
    path: "/authorize/success",
    meta: successXBy77aqb6gMeta || {},
    component: () => import("/opt/render/project/src/pages/authorize/success.vue")
  },
  {
    name: "credits",
    path: "/credits",
    component: () => import("/opt/render/project/src/pages/credits.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjhbtmcfyq0Meta || {},
    component: () => import("/opt/render/project/src/pages/login.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/opt/render/project/src/pages/notifications.vue")
  },
  {
    name: "probes",
    path: "/probes",
    component: () => import("/opt/render/project/src/pages/probes.vue"),
    children: [
  {
    name: "probes-id",
    path: ":id()",
    component: () => import("/opt/render/project/src/pages/probes/[id].vue")
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/render/project/src/pages/settings.vue")
  },
  {
    name: "tokens",
    path: "/tokens",
    component: () => import("/opt/render/project/src/pages/tokens.vue")
  }
]